<script setup lang="ts">
import { hash } from "ohash"

import { helpTextQueryKey } from "~/api/queryKeys"

const props = defineProps<{ text?: string }>()

const { data } = await useDeimosQuery({
  queryKey: computed(() => [helpTextQueryKey, hash(props.text)]),
  queryFn: async () => {
    if (!props.text) return null
    return await parseMarkdown(props.text)
  },
  // Cache forever, since unless the text changes, we don't want to re-fetch
  staleTime: Infinity,
})
</script>
<template>
  <Popper class="text-[16px] font-normal" hover arrow placement="right">
    <div class="flex items-center">
      <Icon
        class="text-[20px] text-dusk-400 hover:text-forest-600"
        name="mingcute:question-fill" />
    </div>
    <template #content>
      <MDCRenderer
        v-if="data?.body"
        tag="p"
        class="max-w-xs break-words"
        :body="data?.body" />
    </template>
  </Popper>
</template>
